import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Typography } from "@material-ui/core"
import { GatsbyImage } from "gatsby-plugin-image"

// import app components
import Textarea from "../../../components/textarea"
import Spacer from "../../../components/spacer"
import ButtonLandingPage from "../../../components/buttonLandingPage"
import LinkBoxes from "../../../components/footer/LinkBoxes"

const LandingPage = (props) => {
  const {
    data: {
      wpPage: {
        landingPage: { header, subheader, secondTextImage, textImage, image, quote }
      }
    }
  } = props

  return (
    <Container>
      <HeadingEdge>
        <Heading>
          <Typography variant="h1" component="h1">
            {header}
          </Typography>
          <Typography variant="h2">{subheader}</Typography>
        </Heading>
      </HeadingEdge>
      <Edges>
        <TextImageContainer>
          <TextContainer>{textImage?.text && <Textarea content={textImage?.text} />}</TextContainer>
          <ImageContainer primary>
            <GatsbyImage
              height={`fit-content`}
              image={
                textImage?.image?.localFile?.childImageSharp?.gatsbyImageData ||
                textImage?.image?.localFile?.childImageSharp?.gatsbyImageData
              }
              alt={textImage?.image?.altText}
              objectFit="contain"
            />
          </ImageContainer>
        </TextImageContainer>
      </Edges>
      <ColorBackground>
        <Edges>
          <TextImageContainer primary>
            <TextContainer primary>
              {secondTextImage?.text && <Textarea className={"textWhite"} content={secondTextImage?.text} />}

              {secondTextImage?.link && (
                <Spacer pt={10} pb={10}>
                  <ButtonLandingPage
                    textcolor={"#FFFFFF"}
                    to={secondTextImage?.link?.url}
                    children={secondTextImage?.link?.title}
                  />
                </Spacer>
              )}
            </TextContainer>

            <ImageContainer>
              <ThreeImage>
                <ImageTopBottom>
                  <GatsbyImage
                    image={
                      secondTextImage?.image?.localFile?.childImageSharp?.gatsbyImageData ||
                      secondTextImage?.image?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt={secondTextImage?.image?.altText}
                  />
                </ImageTopBottom>
                <ImageBetween>
                  <div style={{ width: "100%", height: "auto" }}>
                    <GatsbyImage
                      style={{
                        objectFit: `cover`,
                        objectPosition: `50%`
                      }}
                      objectFit={`cover`}
                      objectPosition={"50%"}
                      alt={secondTextImage?.secondImage?.altText}
                      image={
                        secondTextImage?.secondImage?.localFile?.childImageSharp?.gatsbyImageData ||
                        secondTextImage?.secondImage?.localFile?.childImageSharp?.gatsbyImageData
                      }
                    />
                  </div>
                </ImageBetween>
                <ImageTopBottom>
                  <GatsbyImage
                    image={
                      secondTextImage?.thirdImage?.localFile?.childImageSharp?.gatsbyImageData ||
                      secondTextImage?.thirdImage?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt={secondTextImage?.thirdImage?.altText}
                  />
                </ImageTopBottom>
              </ThreeImage>
            </ImageContainer>
          </TextImageContainer>
        </Edges>
      </ColorBackground>
      <Edges>
        <QuoteContainer>
          {quote?.text && <Textarea className={"conten"} content={quote?.text} />}
          {quote?.link && (
            <Spacer pt={10} pb={10}>
              <ButtonLandingPage textcolor={"#000000"} to={quote?.link?.url} children={quote?.link?.title} />
            </Spacer>
          )}
        </QuoteContainer>
      </Edges>
      <WideImageContainer>
        {image && (
          <GatsbyImage
            height={`fit-content`}
            width={`fit-content`}
            objectFit={`cover`}
            image={
              image?.localFile?.childImageSharp?.gatsbyImageData || image?.localFile?.childImageSharp?.gatsbyImageData
            }
            alt={image?.altText}
          />
        )}
      </WideImageContainer>

      <LinkBoxes marginTop={"none"} />
    </Container>
  )
}

const Container = styled.div``

const HeadingEdge = styled.div`
  margin: 63px auto;
  width: 80%;
  max-width: 1024px;
  h1 {
    font-weight: bold;
    font-size: 36px;
    padding-bottom: 20px;

    @media (min-width: 735px) {
      font-size: 51px;
      padding-bottom: 0;
    }
  }
  h2 {
    font-size: 22px;
    @media (min-width: 735px) {
      font-size: 36px;
    }
  }
`

const Edges = styled.div`
  margin: 0 auto;
  width: 80%;
  max-width: 1024px;
`
const ColorBackground = styled.div`
  background: #2d312c;
`

const TextImageContainer = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props.primary ? "wrap" : "wrap-reverse")};
  align-items: center;
  padding: 0;
  margin: 0;
  justify-content: center;
  @media (min-width: 1180px) {
    justify-content: space-between;
  }
`

const ImageContainer = styled.div`
  order: 2;
  margin: 0;
  padding: 0;
  height: auto;
  @media (min-width: 1180px) {
    width: 60%;
  }
  position: relative;
  .gatsby-image-wrapper {
    max-height: ${(props) => (props.primary ? "440px" : "100%")};
  }
`

const TextContainer = styled.div`
  order: ${(props) => (props.primary ? 3 : 1)};
  .textWhite {
    p {
      color: #c3c9c1;
      font-size: ${(props) => (props.primary ? "16px" : "19px")};
    }
    h2 {
      color: #ffffff;
      font-size: 28px;
      /* font-weight: 400; */
    }
  }
  text-align: center;

  justify-self: flex-start;
  max-width: 100%;
  padding: 30px 0 30px 0;
  @media (min-width: 600px) {
    width: 90%;
  }
  @media (min-width: 800px) {
    width: 70%;
  }
  @media (min-width: 900px) {
    width: 60%;
  }
  @media (min-width: 1180px) {
    width: 40%;
    padding: ${(props) => (props.primary ? "30px 0 30px 40px" : "30px 30px 30px 0")};
  }
  text-align: left;
  justify-self: center;
`
const Heading = styled.div`
  margin-bottom: 42px;
`

const ThreeImage = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageTopBottom = styled.div`
  max-height: 250px;
`
const ImageBetween = styled.div`
  max-height: 198px;
  margin: 4px 0 4px 0;
  z-index: 1;
  position: relative;
`

const QuoteContainer = styled.div`
  text-align: center;
  margin: 141px auto 141px auto;
  max-width: 712px;
  .conten {
    p {
      color: #51656c;
      font-size: 14px;
      @media (min-width: 735px) {
        font-size: 16px;
      }
    }
    h2 {
      color: #51656c;
      font-size: 22px;
      padding-bottom: 12px;
      margin-bottom: 0;
      @media (min-width: 735px) {
        font-size: 28px;
      }
    }
  }
`
const WideImageContainer = styled.div`
  object-fit: cover;
  width: 100%;
  text-align: center;
  .gatsby-image-wrapper {
    height: 573px;
  }
`

export const CollectionQuery = graphql`
  query LandingPageTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      acfModules {
        hideSearch
      }
      landingPage {
        fieldGroupName
        header
        subheader
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(height: 573, layout: FULL_WIDTH, quality: 100)
            }
          }
        }
        quote {
          fieldGroupName
          text
          link {
            target
            title
            url
          }
        }
        textImage {
          fieldGroupName
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 660, layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        secondTextImage {
          fieldGroupName
          text
          textOnImage
          link {
            target
            title
            url
          }
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 540, height: 250, layout: CONSTRAINED, quality: 100)
              }
            }
          }
          secondImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 540, height: 198, layout: CONSTRAINED, quality: 100)
              }
            }
          }
          thirdImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 540, height: 250, layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default LandingPage
