import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// import material ui
import { Button as MuiButton } from "@material-ui/core"
import { getUrlPath } from "utils/getUrlPath"

const ButtonLandingPage = ({ to, textcolor, ...rest }) => {
  if (to) {
    return (
      <StyledLink to={getUrlPath(to)}>
        <StyledButton textcolor={textcolor} {...rest} variant="contained" />
      </StyledLink>
    )
  } else {
    return <StyledButton textcolor={textcolor} {...rest} variant="contained" />
  }
}

export default ButtonLandingPage

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledButton = styled(MuiButton)`
  && {
    position: relative;
    letter-spacing: 1.5px;
    padding: 10px 2em;
    z-index: 1;
    background: none;
    color: ${(props) => props.textcolor};
    overflow: hidden;
    border-radius: 30px;
    border: 1px solid ${(props) => props.textcolor};
    width: auto;
    @media (min-width: 440px) {
      width: 300px;
    }
    box-shadow: none;
    transition: border-color 0.3s, color 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};

    &:hover {
      color: ${(props) => (props.textcolor === "#FFFFFF" ? "#000000" : "#FFFFFF")} !important;

      &:before {
        opacity: 1;
        background-color: ${(props) => (props.textcolor === "#FFFFFF" ? "#FFFFFF" : "#000000")};
        transform: rotate3d(0, 0, 1, 0deg);
        transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 150%;
      height: 100%;
      background: ${(props) => (props.textcolor === "#FFFFFF" ? "#000000" : "#FFFFFF")};
      z-index: -1;
      transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
      transform-origin: 0% 100%;
      transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
    }
  }
`
